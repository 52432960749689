<template>
  <v-card>
    <div v-for="(country, index) in countries" :key="country.name" class="mt-2 pl-2">
      <v-card-title>{{ country.description }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <v-text-field
              label="Price"
              v-model="country.price"
              :disabled="!isEditing || useUnicPrice"
              :rules="priceRules"
              type="number"
              @input="value => updatePrice(index, value)"
            />
            <v-text-field
              label="Discount"
              v-model="country.discount"
              :disabled="!isEditing || useUnicDiscount"
              :rules="discountRules"
              type="number"
              @input="value => updateDiscount(index, value)"
            />
            <div>
              <v-alert v-if="country.price > 0 && country.discount > 0" type="info" dense>
                Discounted Price: {{ calculateDiscountedPrice(country) }}
              </v-alert>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
    </div>
  </v-card>
</template>

<script>
export default {
  name: "CountryPricing",
  props: {
    countries: Array,
    isEditing: Boolean,
    useUnicPrice: Boolean,
    useUnicDiscount: Boolean,
    unicPrice: Number,
    unicDiscount: Number,
    priceRules: Array,
    discountRules: Array
  },
  watch: {
    useUnicPrice(newVal) {
      if (newVal) {
        this.countries.forEach((country, index) => {
          this.updatePrice(index, this.unicPrice);
        });
      }
    },
    useUnicDiscount(newVal) {
      if (newVal) {
        this.countries.forEach((country, index) => {
          this.updateDiscount(index, this.unicDiscount);
        });
      }
    },
    unicPrice(newVal) {
      if (this.useUnicPrice) {
        this.countries.forEach((country, index) => {
          this.updatePrice(index, newVal);
        });
      }
    },
    unicDiscount(newVal) {
      if (this.useUnicDiscount) {
        this.countries.forEach((country, index) => {
          this.updateDiscount(index, newVal);
        });
      }
    }
  },
  methods: {
    updatePrice(index, value) {
      const finalValue = value === "" ? 0 : parseFloat(value);
      this.$emit("update-price", index, parseFloat(value));
      this.countries[index].price = finalValue;
    },
    updateDiscount(index, value) {
      const finalValue = value === "" ? 0 : parseFloat(value);
      this.$emit("update-discount", index, parseFloat(value));
      this.countries[index].discount = finalValue;
    },
    calculateDiscountedPrice(country) {
      const finalPrice = this.useUnicPrice ? this.$parent.unicPrice : country.price;
      const finalDiscount = this.useUnicDiscount ? this.$parent.unicDiscount : country.discount;
      return this.getDiscountedPrice(finalPrice, finalDiscount);
    },
    getDiscountedPrice(price, discount) {
      return (price * (1 - discount / 100)).toFixed(2);
    }
  }
};
</script>
