<template>
  <v-row align="center" wrap>
    <v-col cols="auto">
      <v-checkbox
        v-model="localUseUnicPrice"
        label="Use Unic Price"
        :disabled="!isEditing"
        @change="$emit('update:useUnicPrice', localUseUnicPrice)"
      />
    </v-col>
    <v-col cols="2" v-if="localUseUnicPrice">
      <v-text-field
        label="Unic Price"
        v-model="localUnicPrice"
        :disabled="!isEditing"
        :rules="priceRules"
        type="number"
        @input="event => $emit('update:unicPrice', parseFloat(event))"
      />
    </v-col>
    <v-col cols="auto">
      <v-checkbox
        v-model="localUseUnicDiscount"
        label="Use Unic Discount"
        :disabled="!isEditing"
        @change="$emit('update:useUnicDiscount', localUseUnicDiscount)"
      />
    </v-col>
    <v-col cols="2" v-if="localUseUnicDiscount">
      <v-text-field
        label="Unic Discount"
        v-model="localUnicDiscount"
        :disabled="!isEditing"
        :rules="discountRules"
        type="number"
        @input="event => $emit('update:unicDiscount', parseFloat(event))"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "PricingOptions",
  props: {
    useUnicPrice: Boolean,
    useUnicDiscount: Boolean,
    unicPrice: Number,
    unicDiscount: Number,
    isEditing: Boolean,
    priceRules: Array,
    discountRules: Array
  },
  data() {
    return {
      localUseUnicPrice: this.useUnicPrice,
      localUseUnicDiscount: this.useUnicDiscount,
      localUnicPrice: this.unicPrice,
      localUnicDiscount: this.unicDiscount
    };
  },
  watch: {
    useUnicPrice(val) {
      this.localUseUnicPrice = val;
    },
    useUnicDiscount(val) {
      this.localUseUnicDiscount = val;
    },
    unicPrice(val) {
      this.localUnicPrice = val;
    },
    unicDiscount(val) {
      this.localUnicDiscount = val;
    }
  }
};
</script>
