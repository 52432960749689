<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>Product - {{ form.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="$privilege.hasPrivilege('PRODUCT_UPDATE') && isEditing">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onSubmit" v-bind="attrs" :disabled="!valid" v-on="on">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Save") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="$privilege.hasPrivilege('PRODUCT_UPDATE') && isEditing">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onCancel" v-bind="attrs" v-on="on">
            <v-icon>mdi-minus-circle</v-icon>
          </v-btn>
        </template>
        <span>Cancel Product</span>
      </v-tooltip>
      <v-tooltip bottom v-if="$privilege.hasPrivilege('PRODUCT_UPDATE') && !isNew">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small @click="onEdit" v-bind="attrs" v-on="on">
            <v-icon v-if="isEditing">mdi-close</v-icon>
            <v-icon v-else>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span v-if="isEditing">{{ $i18n.translate("Cancel") }}</span>
        <span v-else>{{ $i18n.translate("Edit") }}</span>
      </v-tooltip>
      <template v-slot:extension>
        <v-tabs v-model="tab">
          <v-tabs-slider color="info"></v-tabs-slider>
          <v-tab>
            Details
          </v-tab>
          <v-tab>
            Prices
          </v-tab>
          <v-tab>
            Category
          </v-tab>
          <v-tab>
            {{ $i18n.translate("Serial Numbers") }}
          </v-tab>
          <v-tab v-if="!isNew">
            Languages
          </v-tab>
          <v-tab>
            Custom Fields
          </v-tab>
        </v-tabs>
      </template>
      <v-tooltip bottom v-if="$privilege.hasPrivilege('PRODUCT_DELETE') && !isNew">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-2" fab small v-bind="attrs" v-on="on" @click="onDelete" :disabled="isEditing">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Delete") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <ApiError :errors="errors"></ApiError>
      <div class="text-center" v-if="isBusy">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>

      <v-form :value="valid" @submit.prevent="onSubmit" v-if="!isBusy" v-model="valid">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <KeyField
                    name="productKey"
                    label="Key"
                    id="productKey"
                    v-model="form.productKey"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'productKey')"
                  ></KeyField>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <v-text-field
                    name="name"
                    label="Name"
                    id="name"
                    v-model="form.name"
                    :rules="rules.name"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'name')"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    name="description"
                    label="Description"
                    id="description"
                    v-model="form.description"
                    :rules="rules.description"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'productKey')"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-text-field
                    name="longDescription"
                    label="Long Description"
                    id="longDescription"
                    v-model="form.longDescription"
                    :rules="rules.longDescription"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'longDescription')"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4">
                  <v-text-field
                    name="modelNumber"
                    label="Model Number"
                    id="modelNumber"
                    v-model="form.modelNumber"
                    :rules="rules.modelNumber"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'modelNumber')"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    name="serialNumber"
                    :label="$i18n.translate('Serial Number')"
                    id="serialNumber"
                    v-model="form.serialNumber"
                    :rules="rules.serialNumber"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'serialNumber')"
                    @input="$error.clearValidationError(errors, 'serialNumber')"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    name="serialNumberRegex"
                    :label="$i18n.translate('Serial Number') + ' Regex'"
                    id="serialNumberRegex"
                    v-model="form.serialNumberRegex"
                    :disabled="!isEditing"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3">
                  <v-text-field
                    name="upc"
                    label="UPC"
                    id="upc"
                    v-model="form.upc"
                    :rules="rules.upc"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'upc')"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    name="price"
                    label="Price"
                    id="price"
                    v-model="form.price"
                    :disabled="!isEditing"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    name="barcode"
                    label="Barcode"
                    id="barcode"
                    v-model="form.barcode"
                    :rules="rules.barcode"
                    :disabled="!isEditing"
                    :error-messages="$error.getValidationError(errors, 'barcode')"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <DateTimePickerField
                    v-model="form.effectiveDate"
                    :disabled="!isEditing"
                    :label="$i18n.translate('Effective Date')"
                    defaultTime="00:00"
                  ></DateTimePickerField>
                </v-col>
                <v-col cols="6">
                  <DateTimePickerField
                    v-model="form.expirationDate"
                    :disabled="!isEditing"
                    :label="$i18n.translate('Expiration Date')"
                    defaultTime="23:59"
                  ></DateTimePickerField>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <ProductPricesTab :productId="form.id" :is-editing="isEditing" @prices-updated="handlePriceUpdate" />
          </v-tab-item>

          <v-tab-item>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <ProductCategoryField
                    v-model="selectedProductCategory"
                    label="Category"
                    :disabled="!isEditing"
                  ></ProductCategoryField>
                </v-col>
              </v-row> </v-container
          ></v-tab-item>
          <v-tab-item>
            <v-card>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-form @submit.stop.prevent="onSearchSubmit">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$i18n.translate('Filter') + ' ' + $i18n.translate('Serial Numbers')"
                    light
                    flat
                    solo-inverted
                    hide-details
                    clearable
                    clear-icon="mdi-close-circle-outline"
                    @click:clear="onSearchClear"
                  ></v-text-field>
                </v-form>
              </v-card-title>
              <v-data-table
                :server-items-length="total"
                :options.sync="options"
                :loading="loading"
                :headers="productSerialNumberHeaders"
                :items="productSerialNumbers"
              >
                <template v-slot:item.updatedDate="{ item }">
                  {{ item.updatedDate | formatDateFromNow }}
                </template>
                <template v-slot:item.productSerialNumberKey="{ item }">
                  {{ item.productSerialNumberKey }}&nbsp;
                  <v-tooltip bottom v-if="item && item.participantProvided">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="primary">mdi-account</v-icon>
                    </template>
                    Provided by the Participant
                  </v-tooltip>
                </template>
                <template v-slot:item.claim="{ item }">
                  <span v-for="claim of item.claims" :key="claim ? claim.id : ''">
                    <router-link :to="{ name: 'claim', params: { id: claim.id } }">
                      {{ claim.claimKey }}
                    </router-link>
                  </span>
                </template>
                <template v-slot:item.participant="{ item }">
                  <span v-for="claim of item.claims" :key="claim ? claim.id : ''">
                    <router-link :to="{ name: 'participant', params: { id: claim.participant.id } }">
                      {{ claim.participant.participantKey + " - " + claim.participant.fullName }}
                    </router-link>
                  </span>
                </template>
                <template v-slot:item.promotion="{ item }">
                  <span v-for="claim of item.claims" :key="claim ? claim.id : ''">
                    <router-link :to="{ name: 'promotion', params: { id: claim.promotion.id } }">
                      {{ claim.promotion.promotionKey }}
                    </router-link>
                  </span>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>

          <v-tab-item v-if="!isNew">
            <ProductLocalized :productId="form.id" />
          </v-tab-item>

          <v-tab-item>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <CustomField
                    v-model="form.customFieldValue1"
                    :disabled="!isEditing"
                    v-if="
                      selectedProgram.programGroup.productCustomFields &&
                        selectedProgram.programGroup.productCustomFields.length >= 1
                    "
                    :customField="selectedProgram.programGroup.productCustomFields[0]"
                  />

                  <CustomField
                    v-model="form.customFieldValue2"
                    :disabled="!isEditing"
                    v-if="
                      selectedProgram.programGroup.productCustomFields &&
                        selectedProgram.programGroup.productCustomFields.length >= 2
                    "
                    :customField="selectedProgram.programGroup.productCustomFields[1]"
                  />

                  <CustomField
                    v-model="form.customFieldValue3"
                    :disabled="!isEditing"
                    v-if="
                      selectedProgram.programGroup.productCustomFields &&
                        selectedProgram.programGroup.productCustomFields.length >= 3
                    "
                    :customField="selectedProgram.programGroup.productCustomFields[2]"
                  />

                  <CustomField
                    v-model="form.customFieldValue4"
                    :disabled="!isEditing"
                    v-if="
                      selectedProgram.programGroup.productCustomFields &&
                        selectedProgram.programGroup.productCustomFields.length >= 4
                    "
                    :customField="selectedProgram.programGroup.productCustomFields[3]"
                  />

                  <CustomField
                    v-model="form.customFieldValue5"
                    :disabled="!isEditing"
                    v-if="
                      selectedProgram.programGroup.productCustomFields &&
                        selectedProgram.programGroup.productCustomFields.length >= 5
                    "
                    :customField="selectedProgram.programGroup.productCustomFields[4]"
                  />

                  <CustomField
                    v-model="form.customFieldValue6"
                    :disabled="!isEditing"
                    v-if="
                      selectedProgram.programGroup.productCustomFields &&
                        selectedProgram.programGroup.productCustomFields.length >= 6
                    "
                    :customField="selectedProgram.programGroup.productCustomFields[5]"
                  />

                  <CustomField
                    v-model="form.customFieldValue7"
                    :disabled="!isEditing"
                    v-if="
                      selectedProgram.programGroup.productCustomFields &&
                        selectedProgram.programGroup.productCustomFields.length >= 7
                    "
                    :customField="selectedProgram.programGroup.productCustomFields[6]"
                  />

                  <CustomField
                    v-model="form.customFieldValue8"
                    :disabled="!isEditing"
                    v-if="
                      selectedProgram.programGroup.productCustomFields &&
                        selectedProgram.programGroup.productCustomFields.length >= 8
                    "
                    :customField="selectedProgram.programGroup.productCustomFields[7]"
                  />

                  <CustomField
                    v-model="form.customFieldValue9"
                    :disabled="!isEditing"
                    v-if="
                      selectedProgram.programGroup.productCustomFields &&
                        selectedProgram.programGroup.productCustomFields.length >= 9
                    "
                    :customField="selectedProgram.programGroup.productCustomFields[8]"
                  />

                  <CustomField
                    v-model="form.customFieldValue10"
                    :disabled="!isEditing"
                    v-if="
                      selectedProgram.programGroup.productCustomFields &&
                        selectedProgram.programGroup.productCustomFields.length >= 10
                    "
                    :customField="selectedProgram.programGroup.productCustomFields[9]"
                  />
                </v-col>
                <v-col cols="6">
                  <h2 class="mb-2">Product Custom Fields</h2>
                  <ol>
                    <li>
                      Configure each custom field at the Program Group level.
                    </li>
                  </ol>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
      <v-dialog v-model="cancelDialog" max-width="650px">
        <v-card>
          <v-card-title>
            <span class="headline">
              Are you sure you want to cancel Product?
            </span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-radio-group v-model="cancelOption">
                  <v-radio
                    :label="$i18n.translate('Cancel') + ' ' + $i18n.translate('Immediately')"
                    value="1"
                  ></v-radio>
                  <v-radio :label="$i18n.translate('Cancel') + ' ' + $i18n.translate('By Date')" value="2"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <DateTimePickerField
                  v-if="cancelOption == 2"
                  v-model="cancelDate"
                  clearable
                  :label="$i18n.translate('Expiration Date')"
                  defaultTime="23:59"
                ></DateTimePickerField>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="onCancelDialog()">{{ $i18n.translate("Cancel") }}</v-btn>
            <v-btn
              color="primary"
              @click="onCancelProduct()"
              :loading="loadingCancel"
              :disabled="cancelOption == 2 && cancelDate == undefined"
              >Agree</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import ApiError from "../../display/ApiError.vue";
import CustomField from "../../fields/CustomField.vue";
import DateTimePickerField from "../../fields/DateTimePickerField.vue";
import KeyField from "../../fields/KeyField.vue";
import ProductCategoryField from "../../fields/ProductCategoryField.vue";
import ProductLocalized from "../../tables/ProductLocalized.vue";
import ProductPricesTab from "./ProductPricesTab.vue";

export default {
  components: {
    ApiError,
    KeyField,
    DateTimePickerField,
    ProductCategoryField,
    ProductLocalized,
    CustomField,
    ProductPricesTab
  },
  name: "Product",
  metaInfo: {
    title: "Product"
  },
  data() {
    return {
      tab: null,
      searchInput: "",
      valid: true,
      isNew: false,
      cancelOption: "1",
      cancelDialog: false,
      loadingCancel: false,
      cancelDate: "",
      isEditing: false,
      isBusy: false,
      form: {},
      errors: {},
      rules: {
        name: [v => !!v || "Name is required"]
      },
      menu: false,
      modal: false,
      effectiveDate: false,
      expirationDate: false,
      selectedProductCategory: null,

      productSerialNumbers: [],
      productSerialNumberHeaders: [],

      search: "",
      loading: false,
      total: 0,
      options: {
        itemsPerPage: 10,
        sortBy: ["updatedDate"],
        sortDesc: [true]
      },
      priceData: null
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchProductSerialNumberData();
      },
      deep: true
    }
  },
  methods: {
    onSearchClear() {
      this.search = "";
      this.fetchProductSerialNumberData();
    },
    onSearchSubmit() {
      this.options.page = 0;
      this.fetchProductSerialNumberData();
    },
    fetchProductSerialNumberData() {
      if (!this.form.id) {
        return Promise.resolve();
      }

      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filters = { product: { id: this.form.id } };
      if (this.search && this.search.length > 0) {
        filters.keyword = this.search;
      }

      return this.$api
        .post(
          "/api/productSerialNumbers/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.loading = false;
          let promiseArray = [];

          data.content.forEach(sn => {
            promiseArray.push(
              this.$api.post("/api/claims/search", { serialNumber: sn.productSerialNumberKey }).then(({ data }) => {
                sn.claims = data.content;
              })
            );
          });

          this.total = data.totalElements;
          Promise.all(promiseArray).then(() => {
            this.productSerialNumbers = data.content;
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.isBusy = true;
      this.errors = {};

      if (this.selectedProductCategory) {
        this.form.productCategory = this.$api.getSelfUrl("productCategories", {
          id: this.selectedProductCategory.id
        });
      }

      if (this.priceData) {
        this.form.prices = this.preparePricesForSave();
      }

      let serviceCall = this.isNew
        ? this.$api.post("/api/products", this.form)
        : this.$api.patch("/api/products/" + this.$route.params.id, this.form);
      serviceCall
        .then(({ data }) => {
          this.isBusy = false;
          this.isEditing = false;
          if (this.isNew) {
            this.isNew = false;
            this.$route.params.id = data.id;
          }
          if (this.loadingCancel) {
            this.loadingCancel = false;
            this.cancelDialog = false;
            this.resetCancelDialog();
          }
          this.form = data;
          this.breadcrumb();
        })
        .catch(error => {
          this.isBusy = false;
          this.isEditing = true;
          this.errors = this.$api.getErrorsFromResponse(error);
        });
    },
    onDelete() {
      this.isBusy = true;
      this.$api
        .delete("/api/products/" + this.$route.params.id)
        .then(() => {
          this.$router.push({ name: "products" });
        })
        .catch(error => {
          this.isBusy = false;
          this.isEditing = false;
          this.errors = this.$api.getErrorsFromResponse(error);
        });
    },
    onEdit() {
      this.isEditing = !this.isEditing;
      if (!this.isEditing) {
        this.fetchData();
      }
    },

    fetchData() {
      if (!this.isNew) {
        this.isBusy = true;
        this.$api.get("/api/products/" + this.$route.params.id).then(({ data }) => {
          this.form = data;
          this.$api
            .getRelatedObject("productCategory", data)
            .then(({ data }) => {
              this.selectedProductCategory = data;
            })
            .catch(() => {
              console.log("No product category found");
              // it's possible to receive a 404 response here
            })
            .finally(() => {
              this.breadcrumb();
              this.isBusy = false;
            });
        });
      }
      this.$api
        .post("/api/organizations/search", {
          product: { id: this.$route.params.id }
        })
        .then(({ data }) => {
          this.organizations = data.content;
        });
    },
    breadcrumb() {
      if (this.isNew) {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: this.$i18n.translate("Clients"),
            to: { name: "clients" },
            exact: true
          },
          {
            text: this.selectedClient.name,
            to: {
              name: "client",
              params: { id: this.selectedClient.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Programs"),
            to: { name: "programs" },
            exact: true
          },
          {
            text: this.selectedProgram.name,
            to: {
              name: "program",
              params: { id: this.selectedProgram.id }
            },
            exact: true
          },
          {
            text: "Products",
            to: { name: "products" },
            exact: true
          },
          { text: "New Product" }
        ]);
      } else {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: this.$i18n.translate("Clients"),
            to: { name: "clients" },
            exact: true
          },
          {
            text: this.selectedClient.name,
            to: {
              name: "client",
              params: { id: this.selectedClient.id },
              exact: true
            }
          },
          {
            text: this.$i18n.translate("Programs"),
            to: { name: "programs" },
            exact: true
          },
          {
            text: this.selectedProgram.name,
            to: {
              name: "program",
              params: { id: this.selectedProgram.id }
            },
            exact: true
          },
          {
            text: "Products",
            to: { name: "products" },
            exact: true
          },
          { text: this.form.productKey }
        ]);
      }
    },
    onCancel() {
      this.cancelDialog = true;
    },
    onCancelDialog() {
      this.cancelDialog = false;
      this.resetCancelDialog();
    },
    onCancelProduct() {
      this.loadingCancel = true;
      if (this.cancelOption == 1) {
        this.form.expirationDate = moment();
      } else {
        this.form.expirationDate = this.cancelDate;
      }
      this.onSubmit();
    },
    resetCancelDialog() {
      this.cancelOption = "1";
      this.cancelDate = undefined;
    },
    handlePriceUpdate(prices) {
      this.priceData = prices;
    },
    preparePricesForSave() {
      let prices = [];
      this.errors = {};
      this.priceData.forEach(element => {
        if (element.price || element.discount) {
          prices.push({
            price: this.useUnicPrice ? this.unicPrice : element.price,
            maximumDiscountPercentage: this.useUnicDiscount
              ? this.convertPercentageToDecimal(this.unicDiscount)
              : this.convertPercentageToDecimal(element.discount),
            country: {
              name: element.name
            }
          });
        }
      });
      return prices;
    },
    convertPercentageToDecimal(percentage) {
      return percentage / 100;
    },
    savePrices(prices) {
      this.$api.patch(`/api/products/${this.form.id}`, { prices: prices }).catch(error => {
        this.errors = this.$api.getErrorsFromResponse(error);
      });
    }
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram"]),
    title() {
      return "Product - " + this.form.name;
    }
  },
  mounted() {
    this.productSerialNumberHeaders = [
      {
        value: "productSerialNumberKey",
        text: this.$i18n.translate("Serial Number")
      },
      {
        value: "claim",
        text: "CLM#"
      },
      {
        value: "participant",
        text: this.$i18n.translate("Participant")
      },
      {
        value: "promotion",
        text: this.$i18n.translate("Promotion")
      },
      {
        value: "updatedDate",
        text: this.$i18n.translate("Last Updated"),
        sortable: true
      }
    ];

    if (this.$route.params.id == 0) {
      this.isNew = true;
      this.isEditing = true;
      this.breadcrumb();
    }
    this.fetchData();
    this.form.programGroup = this.$api.getSelfUrl("programs", this.selectedProgram.programGroup);
  }
};
</script>
