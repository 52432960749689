<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card v-for="item in items" :key="item.id" class="mt-2 pl-2">
          <v-card-title>
            {{ languageDescription(item) }}
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mr-2" fab small @click="onEdit(item)" v-bind="attrs" v-on="on">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>{{ $i18n.translate("Edit") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mr-2" fab small @click="onDelete(item)" v-bind="attrs" v-on="on">
                  <v-icon> mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>{{ $i18n.translate("Delete") }}</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-text-field label="Language" v-model="item.language" readonly></v-text-field>
              </v-col>
              <v-col cols="8">
                <v-text-field label="Product Name" v-model="item.name" readonly></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Image Url" v-model="item.imageSrc" readonly></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Product Image Url" v-model="item.description" readonly></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  name="longDescription"
                  label="Long Description"
                  v-model="editedItem.longDescription"
                  :readonly="isDeleting"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="12" align="right">
        <v-btn color="primary" class="mr-2" @click="onAdd()"
          >{{ $i18n.translate("New ") }}{{ $i18n.translate("Language ") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="editDialog" max-width="650px">
      <v-form v-model="valid">
        <v-card>
          <v-card-title>
            <span v-if="isDeleting" class="headline"> Are you sure you want to delete? </span>
            <span v-else-if="editedIndex == -1" class="headline"> New Language </span>
            <span v-else-if="editedIndex > -1" class="headline"> Edit Language </span>
          </v-card-title>
          <v-card-text>
            <ApiError :errors="errors"></ApiError>
            <v-row>
              <v-col cols="4">
                <LanguageField
                  label="Language"
                  v-model="editedItem.language"
                  class="required"
                  :rules="rules.language"
                  :isEditing="editedIndex > -1 ? true : false"
                >
                </LanguageField>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  label="Product Name"
                  v-model="editedItem.name"
                  class="required"
                  :rules="rules.name"
                  :readonly="isDeleting"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                ><v-text-field label="Image Url" v-model="editedItem.imageSrc" :readonly="isDeleting"></v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="12"
                ><v-text-field
                  label="Description"
                  v-model="editedItem.description"
                  :readonly="isDeleting"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="Long Description"
                  v-model="editedItem.longDescription"
                  :readonly="isDeleting"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="onCancel()">{{ $i18n.translate("Cancel") }}</v-btn>
            <v-btn color="primary" :disabled="!valid" @click="onSubmit()">
              <span v-if="isDeleting"> {{ $i18n.translate("Agree") }}</span>
              <span v-else>{{ $i18n.translate("Save") }}</span></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>
<script>
import ApiError from "../display/ApiError";
import ApiService from "../../services/api.service";
import LanguageField from "../fields/LanguageField";

export default {
  name: "ProductLocalized",
  components: {
    LanguageField,
    ApiError
  },
  props: {
    productId: Number
  },
  data: () => ({
    loading: false,
    valid: false,
    editDialog: false,
    isDeleting: false,
    items: [],
    editedIndex: -1,
    editedItem: {},
    rules: {
      language: [v => !!v || "Language is required"],
      name: [v => !!v || "Name is required"]
    },
    errors: {},
    language: []
  }),

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      this.errors = {};
      ApiService.get("/api/products/" + this.productId + "/localizations")
        .then(data => {
          let localizations = data.data;
          this.items = Object.keys(localizations).map(key => {
            return localizations[key];
          });
        })
        .then(() => {
          ApiService.get("/api/types/languages").then(({ data }) => {
            this.items.forEach(e => {
              if (e.language.length > 0) {
                this.language.push(data.find(x => x.name == e.language));
              }
            });
          });
        })
        .catch(error => {
          this.errors = ApiService.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onEdit(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editDialog = true;
    },
    onCancel() {
      this.editedIndex = -1;
      this.editedIndex = {};
      this.errors = {};
      if (this.isDeleting) {
        this.isDeleting = false;
      }
      this.editDialog = false;
    },

    onDelete(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editDialog = true;
      this.isDeleting = true;
    },

    onAdd() {
      this.editedIndex = -1;
      this.editedItem = {
        language: "",
        productNumber: "",
        name: "",
        description: "",
        imageUrl: "",
        videoUrl: ""
      };
      this.editDialog = true;
    },

    onSubmit() {
      this.loading = true;
      let serviceCall = null;
      if (this.isDeleting) {
        serviceCall = ApiService.delete(
          "/api/products/" + this.productId + "/localization/" + this.editedItem.language.name
        );
      } else {
        let productLocalized = {};
        productLocalized.language = this.editedItem.language.name.toLowerCase();
        productLocalized.productNumber = this.editedItem.productNumber;
        productLocalized.name = this.editedItem.name;
        productLocalized.description = this.editedItem.description;
        productLocalized.imageUrl = this.editedItem.imageUrl;
        productLocalized.videoUrl = this.editedItem.videoUrl;

        if (this.editedIndex == -1) {
          serviceCall = ApiService.post("/api/products/" + this.productId + "/localization", {
            productLocalized: productLocalized
          });
        } else {
          productLocalized.id = this.editedItem.id;
          serviceCall = ApiService.put("/api/products/" + this.productId + "/localization", {
            productLocalized: productLocalized
          });
        }
      }

      serviceCall
        .then(() => {
          this.onCancel();
          this.fetchData();
        })
        .catch(error => {
          this.errors = ApiService.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    languageDescription(item) {
      let language = this.language.find(x => x.name == item.language);
      if (language) {
        return language.description;
      }
    }
  }
};
</script>
