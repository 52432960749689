<template>
  <v-container class="text-center" v-if="isLoading">
    <v-progress-circular indeterminate color="primary" />
  </v-container>
  <v-container v-else>
    <v-row>
      <v-col cols="12">
        <PricingOptions
          :use-unic-price="useUnicPrice"
          :use-unic-discount="useUnicDiscount"
          :unic-price="unicPrice"
          :unic-discount="unicDiscount"
          :is-editing="isEditing"
          :price-rules="priceRules"
          :discount-rules="discountRules"
          @update:useUnicPrice="useUnicPrice = $event"
          @update:useUnicDiscount="useUnicDiscount = $event"
          @update:unicPrice="unicPrice = $event"
          @update:unicDiscount="unicDiscount = $event"
        />
        <CountryPricing
          :countries="countries"
          :is-editing="isEditing"
          :use-unic-price="useUnicPrice"
          :use-unic-discount="useUnicDiscount"
          :unic-price="unicPrice"
          :unic-discount="unicDiscount"
          :price-rules="priceRules"
          :discount-rules="discountRules"
          @update-price="updatePrice"
          @update-discount="updateDiscount"
        />
        <ApiError :errors="errors" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import ApiError from "@/gapp-components/components/display/ApiError.vue";
import PricingOptions from "@/gapp-components/components/views/promotions/PricingOptions.vue";
import CountryPricing from "@/gapp-components/components/views/promotions/CountryPricing.vue";

export default {
  name: "ProductPricesTab",
  components: { ApiError, PricingOptions, CountryPricing },
  props: {
    productId: Number,
    isEditing: Boolean
  },
  data: () => ({
    isLoading: false,
    useUnicPrice: false,
    useUnicDiscount: false,
    unicPrice: null,
    unicDiscount: null,
    countries: [],
    errors: {},
    priceRules: [
      v =>
        v === "" ||
        v === null ||
        v === undefined ||
        (!isNaN(parseFloat(v)) && parseFloat(v) >= 0) ||
        "Price must be a number equal or greater than 0"
    ],
    discountRules: [
      v =>
        v === "" ||
        v === null ||
        v === undefined ||
        (!isNaN(parseFloat(v)) && parseFloat(v) >= 0 && parseFloat(v) <= 100) ||
        "Discount must be a number between 0 and 100"
    ]
  }),
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      this.countries = this.getDefaultCountries();
      if (!this.productId) {
        this.isLoading = false;
        return;
      }
      this.$api
        .get(`/api/products/${this.productId}`)
        .then(response => {
          const { data } = response;
          this.processData(data);
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    processData(data) {
      // Get list of all countries from the provided data
      const countriesWithData = data.prices.map(p => p.country.name);
      const allCountries = this.getDefaultCountries().map(c => c.name);
      const allCountriesCovered = allCountries.every(country => countriesWithData.includes(country));

      // Filter nulls and count unique prices
      const prices = data.prices.map(p => p.price);
      const validPrices = prices.filter(price => price !== null && price !== undefined);
      const uniquePrices = new Set(validPrices);

      // Activate unicPrice only if all expected countries have data and all prices are the same
      if (allCountriesCovered && uniquePrices.size === 1) {
        this.useUnicPrice = true;
        this.unicPrice = validPrices[0]; // All the same, take the first
      } else {
        this.useUnicPrice = false;
        this.unicPrice = null;
      }

      // Apply similar logic for discounts
      const discounts = data.prices.map(p => this.convertDecimalToPercentage(p.maximumDiscountPercentage));
      const validDiscounts = discounts.filter(discount => discount !== null && discount !== undefined);
      const uniqueDiscounts = new Set(validDiscounts);

      if (allCountriesCovered && uniqueDiscounts.size === 1) {
        this.useUnicDiscount = true;
        this.unicDiscount = validDiscounts[0];
      } else {
        this.useUnicDiscount = false;
        this.unicDiscount = null;
      }

      this.countries.forEach(country => {
        const priceObj = data.prices.find(p => p.country.name === country.name);
        if (priceObj && priceObj.price !== null) {
          Vue.set(country, "price", priceObj.price);
        }
        if (priceObj && priceObj.maximumDiscountPercentage !== null) {
          Vue.set(country, "discount", this.convertDecimalToPercentage(priceObj.maximumDiscountPercentage));
        }
      });
    },
    getDefaultCountries() {
      return [
        { name: "USA", description: "United States" },
        { name: "CAN", description: "Canada" }
      ];
    },
    calculateDiscountedPrice(item) {
      let finalPrice = this.useUnicPrice ? this.unicPrice : item.price;
      let finalDiscount = this.useUnicDiscount ? this.unicDiscount : item.discount;
      return this.getDiscountedPrice(finalPrice, finalDiscount);
    },
    getDiscountedPrice(price, discount) {
      return (price * (1 - discount / 100)).toFixed(2);
    },
    convertDecimalToPercentage(decimal) {
      return decimal * 100;
    },
    updatePrice(index, value) {
      const price = value === "" ? 0 : parseFloat(value);
      Vue.set(this.countries, index, { ...this.countries[index], price: price });
      this.$emit("prices-updated", this.countries);
    },
    updateDiscount(index, value) {
      const discount = value === "" ? 0 : parseFloat(value);
      Vue.set(this.countries, index, { ...this.countries[index], discount: discount });
      this.$emit("prices-updated", this.countries);
    }
  }
};
</script>
